<template>
  <section id="StrategicAdvisor" class="StrategicAdvisor my-4">
    <div class="container-fluid text-center">
      <div class="container-wetforest">
        <div class="w-100 d-flex justify-content-start ms-8">
          <h2>
            <span style="color: #14993c"> Español </span>
          </h2>
        </div>
        <div class="row m-0 form">
          <div class="col-md-3">
            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_detectar_desktop.webp"
                alt="Imagen detectar (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              ></div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #00a533"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataES.cards.first.title"
                  />

                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataES.cards.first.description"
                  />
                </div>
              </div>
            </div>

            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_ideas_desktop.webp"
                alt="Imagen transformar ideas (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              >
                <h4 id="strategicTransformarH6a" class="card-title">
                  {{ dataES.cards.second.title }}
                </h4>
              </div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #40b9e6"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataES.cards.second.title"
                  />

                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataES.cards.second.description"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_brechas_desktop.webp"
                alt="Imagen acortar brechas (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              >
                <h4 id="strategicAcortarH6a" class="card-title">
                  {{ dataES.cards.third.title }}
                </h4>
              </div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #edd621"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataES.cards.third.title"
                  />
                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataES.cards.third.description"
                  />
                </div>
              </div>
            </div>

            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_fortalecer_desktop.webp"
                alt="Imagen crear y fortalecer (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              >
                <h4 id="strategicCrearH6a" class="card-title">
                  {{ dataES.cards.fourth.title }}
                </h4>
              </div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #00a533"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataES.cards.fourth.title"
                  />
                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataES.cards.fourth.description"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-none d-lg-block mb-4 text-start">
              <input
                class="form-control form-control-lg wet-form fw-bold mb-2"
                type="text"
                v-model="dataES.blackTitle"
                style="color: #14993c"
              />
              <input
                class="form-control form-control-lg wet-form fw-bold mb-2"
                type="text"
                v-model="dataES.greenTitle"
              />
              <input
                class="form-control wet-form mb-2"
                type="text"
                v-model="dataES.description"
              />
            </div>
            <div class="row m-0">
              <div class="col-md-6 pr-mobile-advisors mb-4">
                <div class="container-over elevation">
                  <img
                    class="w-100 img-sobre d-desktop"
                    src="@/assets/img/fondo_definir_desktop.webp"
                    alt="Imagen definir (mobile)"
                    loading="lazy"
                  />
                  <div
                    class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
                  >
                    <h4 id="strategicDefinirH6a" class="card-title">
                      {{ dataES.cards.fifth.title }}
                    </h4>
                  </div>
                  <div
                    class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                    style="background-color: #edd621"
                  >
                    <div class="text-center">
                      <input
                        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                        type="text"
                        v-model="dataES.cards.fifth.title"
                      />
                      <textarea
                        class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                        rows="5"
                        type="text"
                        v-model="dataES.cards.fifth.description"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pl-mobile-advisors mb-4">
                <div class="container-over elevation">
                  <img
                    class="w-100 img-sobre d-desktop"
                    src="@/assets/img/fondo_comprometer_desktop.webp"
                    alt="Imagen comprometer (mobile)"
                    loading="lazy"
                  />
                  <div
                    class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
                  >
                    <h4 id="strategicComprometerH6a" class="card-title">
                      {{ dataES.cards.sixth.title }}
                    </h4>
                  </div>
                  <div
                    class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                    style="background-color: #40b9e6"
                  >
                    <div class="text-center">
                      <input
                        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                        type="text"
                        v-model="dataES.cards.fifth.title"
                      />
                      <textarea
                        class="form-control wet-form texto fw-bold text-center mb-2"
                        rows="5"
                        type="text"
                        v-model="dataES.cards.fifth.description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100 d-flex justify-content-start ms-8">
            <button
              class="btn btn-success btn-lg mt-3 mb-3"
              @click="syncContent('ES')"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="container-fluid text-center">
      <div class="container-wetforest">
        <div class="w-100 d-flex justify-content-start ms-8">
          <h2>
            <span style="color: #14993c"> English </span>
          </h2>
        </div>
        <div class="row m-0 form">
          <div class="col-md-3">
            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_detectar_desktop.webp"
                alt="Imagen detectar (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              ></div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #00a533"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataEN.cards.first.title"
                  />

                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataEN.cards.first.description"
                  />
                </div>
              </div>
            </div>

            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_ideas_desktop.webp"
                alt="Imagen transformar ideas (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              >
                <h4 id="strategicTransformarH6a" class="card-title">
                  {{ dataEN.cards.second.title }}
                </h4>
              </div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #40b9e6"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataEN.cards.second.title"
                  />

                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataEN.cards.second.description"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_brechas_desktop.webp"
                alt="Imagen acortar brechas (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              >
                <h4 id="strategicAcortarH6a" class="card-title">
                  {{ dataEN.cards.third.title }}
                </h4>
              </div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #edd621"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataEN.cards.third.title"
                  />
                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataEN.cards.third.description"
                  />
                </div>
              </div>
            </div>

            <div class="container-over elevation mb-4">
              <img
                class="w-100 img-sobre d-desktop"
                src="@/assets/img/fondo_fortalecer_desktop.webp"
                alt="Imagen crear y fortalecer (mobile)"
                loading="lazy"
              />
              <div
                class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
              >
                <h4 id="strategicCrearH6a" class="card-title">
                  {{ dataEN.cards.fourth.title }}
                </h4>
              </div>
              <div
                class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                style="background-color: #00a533"
              >
                <div class="text-center">
                  <input
                    class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                    type="text"
                    v-model="dataEN.cards.fourth.title"
                  />
                  <textarea
                    class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                    rows="5"
                    type="text"
                    v-model="dataEN.cards.fourth.description"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-none d-lg-block mb-4 text-start">
              <input
                class="form-control form-control-lg wet-form fw-bold mb-2"
                type="text"
                v-model="dataEN.blackTitle"
                style="color: #14993c"
              />
              <input
                class="form-control form-control-lg wet-form fw-bold mb-2"
                type="text"
                v-model="dataEN.greenTitle"
              />
              <input
                class="form-control wet-form mb-2"
                type="text"
                v-model="dataEN.description"
              />
            </div>
            <div class="row m-0">
              <div class="col-md-6 pr-mobile-advisors mb-4">
                <div class="container-over elevation">
                  <img
                    class="w-100 img-sobre d-desktop"
                    src="@/assets/img/fondo_definir_desktop.webp"
                    alt="Imagen definir (mobile)"
                    loading="lazy"
                  />
                  <div
                    class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
                  >
                    <h4 id="strategicDefinirH6a" class="card-title">
                      {{ dataEN.cards.fifth.title }}
                    </h4>
                  </div>
                  <div
                    class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                    style="background-color: #edd621"
                  >
                    <div class="text-center">
                      <input
                        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                        type="text"
                        v-model="dataEN.cards.fifth.title"
                      />
                      <textarea
                        class="form-control form-control-lg wet-form texto fw-bold text-center mb-2"
                        rows="5"
                        type="text"
                        v-model="dataEN.cards.fifth.description"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pl-mobile-advisors mb-4">
                <div class="container-over elevation">
                  <img
                    class="w-100 img-sobre d-desktop"
                    src="@/assets/img/fondo_comprometer_desktop.webp"
                    alt="Imagen comprometer (mobile)"
                    loading="lazy"
                  />
                  <div
                    class="texto-sobre-img-valores d-flex align-items-center justify-content-center"
                  >
                    <h4 id="strategicComprometerH6a" class="card-title">
                      {{ dataEN.cards.sixth.title }}
                    </h4>
                  </div>
                  <div
                    class="over-texto-sobre-img d-flex align-items-center justify-content-center"
                    style="background-color: #40b9e6"
                  >
                    <div class="text-center">
                      <input
                        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
                        type="text"
                        v-model="dataEN.cards.fifth.title"
                      />
                      <textarea
                        class="form-control wet-form texto fw-bold text-center mb-2"
                        rows="5"
                        type="text"
                        v-model="dataEN.cards.fifth.description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-start ms-8">
          <button
            class="btn btn-success btn-lg mt-3 mb-3"
            @click="syncContent('EN')"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import {ConsultaSeccionStrategicAdvisor} from "@/helpers/contenido.js";
//import store from "../../store";

import { getSection, putSection } from "../../helpers/contenido";
export default {
  name: "StrategicAdvisorAdmin",
  data() {
    return {
      dataES: {
        greenTitle: "Strategic Advisors",
        blackTitle: "lideras los programas",
        description:
          "A través de un acompañamiento estrecho y comprometido para:",
        cards: {
          first: {
            title: "Detectar",
            description: "En forma rápida desafíos y oportunidades de mejora.",
          },
          second: {
            title: "Transformar Ideas",
            description: "En acciones y/o proyectos concretos.",
          },
          third: {
            title: "Acortar Brechas",
            description:
              "Entre situaciones actuales e ideales, con métricas de resultados, sincronizadas con el alineamiento estratégico de la compañía.",
          },
          fourth: {
            title: "Crear y Fortalecer",
            description:
              "Procesos de mejora continua de la compañia, estructurado para su crecimineto y escalamiento global.",
          },
          fifth: {
            title: "Definir",
            description: "Roles y funciones claras.",
          },
          sixth: {
            title: "Comprometer",
            description:
              "Los programas con la ejecución, completando la organización, conscientes de las dificultades de compatibilizar el día a día con acciones de mayor alcance.",
          },
        },
      },
      dataEN: {
        greenTitle: "Strategic Advisors",
        blackTitle: "lideras los programas",
        description:
          "A través de un acompañamiento estrecho y comprometido para:",
        cards: {
          first: {
            title: "Detectar",
            description: "En forma rápida desafíos y oportunidades de mejora.",
          },
          second: {
            title: "Transformar Ideas",
            description: "En acciones y/o proyectos concretos.",
          },
          third: {
            title: "Acortar Brechas",
            description:
              "Entre situaciones actuales e ideales, con métricas de resultados, sincronizadas con el alineamiento estratégico de la compañía.",
          },
          fourth: {
            title: "Crear y Fortalecer",
            description:
              "Procesos de mejora continua de la compañia, estructurado para su crecimineto y escalamiento global.",
          },
          fifth: {
            title: "Definir",
            description: "Roles y funciones claras.",
          },
          sixth: {
            title: "Comprometer",
            description:
              "Los programas con la ejecución, completando la organización, conscientes de las dificultades de compatibilizar el día a día con acciones de mayor alcance.",
          },
        },
      },
      // titulo_negro: '',
      // titulo_verde: '',
      // description: '',
      // detectar_card: '',
      // ideas_card: '',
      // acortar_card: '',
      // crear_card: '',
      // definir_card: '',
      // comprometer_card: ''
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("strategicAdvisors");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "strategicAdvisors",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "strategicAdvisors",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.texto {
  font-size: 1rem !important;
}

.StrategicAdvisor {
  position: relative;
  margin-top: 5%;

  p {
    font-family: InterMedium !important;
    font-size: 1.25rem !important;
  }
}

.sub-titulo {
  color: #414141;
  text-align: left;
}

.container-over {
  position: relative;
  overflow: hidden;
}

.container-wetforest {
  padding-left: 8%;
  padding-right: 8%;
}

.img-sobre {
  border-radius: 18px;
}

.texto-sobre-img-valores {
  position: absolute;
  top: 0;
  left: 0px;
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  /* text-align: center; */
  z-index: 2;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.over-texto-sobre-img {
  position: absolute;
  top: 0;
  left: 0px;
  border: none;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 1;
  color: #fff;
  padding: 1rem;
  border-radius: 18px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
</style>
